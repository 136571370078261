/* Colors */
$pink: #d33682;
$red: #e12548;
$orange: #e88714;
$teal: #1bbeb8;
$lightblue: #47b5f5;
$blue: #1c71b9;
$green: #07b05a;
$purple: #8e44ad;
$light-green: #d6f1d6;
$grey-blue: #677d8b;
$dark-blue: #112939;
$grey1: #ebebeb;
$grey2: #dfdfdf;
$grey3: #b9b9b9;
$grey4: #656565;
$grey5: #323232;
$white: #ffffff;
$black: #000000;
