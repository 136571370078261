@font-face {
  font-family: Flaticon;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/flaticon.eot");
  src:
    url("../fonts/flaticon.eot#iefix") format("embedded-opentype"),
    url("../fonts/flaticon.woff") format("woff"),
    url("../fonts/flaticon.ttf") format("truetype"),
    url("../fonts/flaticon.svg") format("svg");
}

[class^="flaticon-"]::before,
[class*=" flaticon-"]::before,
[class^="flaticon-"]::after,
[class*=" flaticon-"]::after {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: Flaticon;
  font-size: 20px;
  font-style: normal;
}

.flaticon-add::before {
  content: "\e000";
}

.flaticon-sheet::before {
  content: "\e001";
}

.flaticon-edit::before {
  content: "\e002";
}

.flaticon-folder::before {
  content: "\e003";
}

.flaticon-google::before {
  content: "\e004";
}

.flaticon-notification::before {
  content: "\e005";
}

.flaticon-folder-shared::before {
  content: "\e006";
}

.flaticon-chat::before {
  content: "\e007";
}
