@use "colors";
@import "fonts";
@import "variables";

body,
html {
  font-family: "Noto Sans", Helvetica, Arial, sans-serif, "Noto Color Emoji", "Noto Emoji", "Noto Sans Symbols",
    "Noto Sans Symbols 2", "Noto Sans Egyptian Hieroglyphs", "Noto Sans Math", "Noto Music"; /* fallbacks to display uncommon unicode chars without tofu-ing */

  -webkit-font-smoothing: antialiased;
  height: 100%;
  margin: 0;
  overscroll-behavior-x: none;
}

// Remove built-in form typography styles
input,
button,
textarea,
select {
  font-family: inherit;
}

.dashboard {
  height: 100%;
  overflow-x: hidden;

  .error {
    background: #bf0000;
    border-radius: 3px;
    color: white;
    font-size: 12px;
    margin-bottom: 10px;
    padding: 8px;
  }

  $triangle-size: 6px;

  .collapsed,
  .uncollapsed {
    display: inline-block;
    height: 0;
    width: 0;
  }

  .collapsed {
    border-bottom: $triangle-size - 2px solid transparent;
    border-left: $triangle-size solid #565656;
    border-top: $triangle-size - 2px solid transparent;
    margin-right: 2px;
  }

  .uncollapsed {
    border-left: $triangle-size - 2px solid transparent;
    border-right: $triangle-size - 2px solid transparent;
    border-top: $triangle-size solid #565656;
    margin-bottom: 3px;
    margin-right: 2px;
  }

  .header {
    align-items: center;
    background: #414141;
    display: flex;
    padding: 5px 12px;

    .header-container {
      flex: 1;
    }

    h1 {
      color: white;
      display: inline-block;
      font-size: 24px;
      margin: 0;
      margin-right: 10px;

      img.icon {
        margin-right: 10px;
        position: relative;
        top: 3px;
        width: 24px;
      }

      a {
        color: white;
        text-decoration: none;
      }
    }

    .current-hunt a {
      color: #19ceec;
    }

    .hunt-label {
      color: white;
    }

    .route-link {
      border-bottom: 3px solid transparent;
      color: white;
      cursor: pointer;
      font-size: 11px;
      height: 18px;
      margin-bottom: -5px;
      margin-left: 10px;
      margin-top: 10px;
      padding-bottom: 3px;
      text-decoration: none;
      text-transform: uppercase;

      &.active-link {
        border-color: #19ceec;
        color: #19ceec;
        font-weight: 600;
      }

      &:not(.active-link):hover {
        color: #19ceec;
      }
    }
  }
}

.route-link + .logout-button {
  display: flex;

  &::before {
    border-left: 1px solid #aaaaaa;
    content: "";
    display: inline-block;
    height: 18px;
    margin-left: 5px;
    margin-top: -2px;
    padding-left: 15px;
    width: 1px;
  }
}

.forbidden,
.not-found {
  padding: 0 15px;
}

.development-tag {
  background: red;
  border-radius: 10px;
  color: white;
  font-size: 11px;
  font-weight: 600;
  padding: 2px 5px;
  text-transform: uppercase;
  white-space: nowrap;
}

table {
  thead th {
    background: colors.$grey5;
    color: white;
    font-size: 10px;
    font-weight: 600;
    padding: 4px;
    text-align: left;
    text-transform: uppercase;
  }

  tbody tr {
    background: white;

    &:nth-child(even) {
      background: colors.$grey2;
    }

    td {
      font-size: 12px;
      padding: 4px 8px 4px 4px;

      button {
        margin-right: 5px;
      }
    }

    &.loading-row td {
      background: colors.$grey2;
      color: colors.$grey4;
      padding: 10px;
      text-align: center;
    }
  }
}

b {
  font-weight: 900;
}

button {
  border: 0;
  font-size: 1rem;
  padding: 0;
}

code {
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 6px;
  color: #565656;
  display: inline-block;
  font-family: monospace;
  font-size: 13px;
  line-height: 1.3em;
  margin: 5px 0 10px;
  padding: 5px 10px;
}
